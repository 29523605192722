import React, { useEffect } from "react";

import gsap from "gsap";
import { brown } from "../theme/colors";
import { Link } from "gatsby";

const NotFoundPage = (props) => {
  const { dimensions } = props;

  const tl = gsap.timeline();

  useEffect(() => {
    tl.to(".banner h1", {
      opacity: 1,
      duration: 0.25,
      delay: dimensions.width < 1024 ? 0 : 1,
    })
      .to("#header", {
        opacity: 1,
        y: 0,
        duration: 0.25,
        delay: dimensions.width < 1024 ? 0 : -0.25,
      })
      .to(".example--2", {
        opacity: 0.6,
        duration: 0.25,
        delay: dimensions.width < 1024 ? 0 : -0.25,
      });
  }, []);

  return (
    <div
      style={{
        backgroundColor: brown.light,
        padding: "20px",
        height: "calc(100vh - 68px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <h1>Erro 404</h1>
      <p>
        Não foi possível encontrar a página que procura - volte à{" "}
        <Link to="/">Homepage</Link>
      </p>
    </div>
  );
};

export default NotFoundPage;
